.my-table-white tbody tr:nth-child(odd){
  background: #F8F8F8;
}
.my-table-white tbody tr:nth-child(even){
  background: #FFFFFF;
}

div.row-report{ background: #FFF !important;}
.btn-middle-center{
  display: flex;
  height: 100%;
  align-items:center;
  justify-content: center;
}

.pk10-containfer-fluid .input-prepend input[type='text']{
  border: 1px solid #c2cfd6;
  padding: 6px 12px;
}

.card-header .switch.float-right{ margin-bottom: 0 !important;}
.pncasino-navbar-nav,.pncasino-navbar-nav li{ height: 100% !important; line-height: 54px;}
.pncasino-navbar-nav .icon-list
{ font-size: 18px !important; color: #e8f3f4;}
.pncasino-navbar-nav .icon-list:hover{ color: #cef5f7;}
.pncasino-navbar-nav .d-md-down-none{ padding:0 10px; color: #e8f3f4;}
.pncasino-navbar-nav .d-md-down-none i{ font-style: normal; color: #bae6e9;}
.pncasino-login-area{ text-align: center;}
.pncasino-login-area button{
  background-color: #4285f4;
  -webkit-transition: box-shadow .28s cubic-bezier(0.4,0.0,0.2,1);
  transition: box-shadow .28s cubic-bezier(0.4,0.0,0.2,1);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
  border-radius: 4px; font-size: 1rem; padding: 9px 28px; margin-top: 15px;
}