.row.row-equal {
  padding-right: ($grid-gutter-width / 4);
  padding-left:  ($grid-gutter-width / 4);
  margin-right: ($grid-gutter-width / -2);
  margin-left:  ($grid-gutter-width / -2);

  [class*="col-"] {
    padding-right: ($grid-gutter-width / 4);
    padding-left:  ($grid-gutter-width / 4);
  }
}

.main .container-fluid {
  padding: 0 20px;
}
